<template>
  <div>
    <van-nav-bar title="欠账排行" left-text="返回" left-arrow @click-left="onClickLeft" style="margin-bottom: 5px;">
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="item in dataList" :key="item.id" :name="item.id">
          <div class="van-coupon">
            <div class="van-coupon__content" style="padding: 0px;">
              <div class="van-coupon__head">
                <h2 class="van-coupon__amount">{{ item.totalPrice }}<span>元</span></h2>
              </div>
              <div class="van-coupon__body" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                <div class="van-coupon__name">{{ item.realName }}</div>
                <!--                <div class="van-coupon__valid" style="margin-bottom: 5px; font-size: 14px;">{{ item.createTime }}</div>-->
                <div role="checkbox" tabindex="0" aria-checked="true" class=" van-coupon__corner" size="18">
                  <van-button type="primary" size="mini" @click="toDebtInfo(item)">详情</van-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {
  ContactCard,
  Loading, PullRefresh, List,
  CouponCell, NavBar, RadioGroup, Radio, Checkbox
} from 'vant';

import * as service from "@/api/admin/analysis";

export default {
  components: {
    [ContactCard.name]: ContactCard,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [CouponCell.name]: CouponCell,
    [NavBar.name]: NavBar,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
  },
  name: "DebtList",
  data() {
    return {
      searchParam: {},
      orderInfoPopupShow: false,
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      orderInfos: [],
      isPaymentShow: false,
      totalPrice: 0,
      paymentPrice: 0,
      payPrice: 0,
      mark: null,
      paymentStatusChecked: '0',
      paymentTypeChecked: '微信',
      verifySuccess: true,
      debtId: null,
    };
  },
  created() {
    console.log(this.searchParam)
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    toDebtInfo(item) {
      this.$router.push({path: '/order/debt/search', query: {userId: item.userId}})
    },
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      // let param = 'page=' + this.pages.currPage + '&limit=10';
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      console.log('pages', this.pages)
      // if (this.orderInfo.userId !== undefined) {
      //   param += '&accountUserId=' + this.orderInfo.userId;
      // }
      let that = this;
      service.getCreditList(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          console.log('pages', this.pages)
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }
          console.log(that.dataList)
        }
      });
    },
    onRefresh() {
      console.log('父亲刷新我')
      console.log(this.searchParam)
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      console.log(this.pages)
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
  },
};
</script>

<style lang="less">
.notice-swipe {
  height: 30px;
  line-height: 30px;
}

.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}

.order-tag-margin-left {
  margin-left: 2px
}
</style>
